import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentMision = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "257c303e-6928-566d-94f6-47936a4d27e1"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentMision

