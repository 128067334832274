import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentName = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "c4d7a824-58dd-5584-9f0c-9849fd84ac96"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentName

