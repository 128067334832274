import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Image } from "react-bootstrap"
import { Row, Col, Tabs, Tab } from "react-bootstrap"

import Layout from "../components/layout"
import ComponentProyecto from "../hooks/proyecto"
import ComponentObjetivo from "../hooks/objetivo"
import ComponentMision from "../hooks/mision"
import ComponentEcosistema from "../hooks/ecosistema"
import SEO from "../components/seo"



const Integrantes = ({ data }) => (
  <Layout pageInfo={{ pageName: "El proyecto" }}>
    <SEO title="El proyecto" />
    <br />
    <br />
    <br></br>
    <Tabs defaultActiveKey="proyecto" id="uncontrolled-tab-example" className="mb-4 nav-fill integrantes-tab">
      <Tab eventKey="proyecto" style={{textAlign: `justify`}} title="Proyecto" className="tabsBorder pt-3">
        <ComponentProyecto></ComponentProyecto>
      
      </Tab>
      <Tab eventKey="Objetivo" style={{textAlign: `justify`}} title="Objetivos" className="pt-3">
        <ComponentObjetivo></ComponentObjetivo>
      </Tab>
      <Tab eventKey="mision" style={{textAlign: `justify`}} title="Misión y Visión" className="pt-3">
        <ComponentMision></ComponentMision>
      </Tab>
      <Tab eventKey="equipo" style={{textAlign: `justify`}} title="Equipo de Investigación" className="pt-3">
        <Row className="justify-content-md-center">
          {data.lider.edges.map(({ node }) => (
            <Col sm={6} style={{ textAlign: `-webkit-center` }}>
              <div class="image-area">
                <div class="img-wrapper">
                  <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
                  <h3>{node.field_nombre} {node.field_apellido}</h3>
                  <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}</p></Link>
                  <ul>
                    <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
                  </ul>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <br />
        <Row className="justify-content-md-center">

          {data.allParticipantes.edges.map(({ node }) => (
            <Col sm={3} style={{ textAlign: `-webkit-center` }}>
              <div class="image-area">
                <div class="img-wrapper">
                  <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
                  <h3>{node.field_nombre} {node.field_apellido}</h3>
                  <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}
                   </p></Link>
                  <ul>
                    <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
                  </ul>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Tab>
      <Tab eventKey="ecosistema" style={{textAlign: `justify`}} title="Ecosistema Tec" className="pt-3">
            <ComponentEcosistema></ComponentEcosistema>
      </Tab>
    </Tabs>
  </Layout>
)
export default Integrantes

export const query = graphql`
  query allNodeIntegrantesAndAllNodeIntegrantes  {
    lider: allNodeIntegrantes(sort: {order: DESC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {eq: "Líder"}}, field_pagina: {elemMatch: {name: {eq: "openResearchLab"}}}}}) {
      edges {
        node {
          field_nombre
          field_apellido
          id
          path {
            alias
          }
          body {
            value
          }
          relationships {
            field_fotografia {
              uri {
                url
              }
            }
            field_categoria {
              name
            }
            field_campus {
              name
            }
            field_pais {
              relationships {
                field_bandera {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    
    allParticipantes: allNodeIntegrantes(sort: {order: ASC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {in: ["Especialista", "Profesor investigador", "Profesora investigadora","Estudiante Investigador","Estudiante Investigadora"]}},, field_pagina: {elemMatch: {name: {eq: "openResearchLab"}}}}}) {
      edges {
        node {
          field_nombre
          field_apellido
          id
          path {
            alias
          }
          body {
            value
          }
          relationships {
            field_fotografia {
              uri {
                url
              }
            }
            field_categoria {
              name
            }
            field_campus {
              name
            }
            field_pais {
              relationships {
                field_bandera {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
